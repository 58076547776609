/*
 * @Author: kelvinywang
 * @Date: 2022-06-22 16:24:34
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-06-25 15:03:17
 * @Description: 页面监控
 */
import type Aegis from 'aegis-web-sdk';
import { perfAppStart } from '@/services/performance/lifecycles';
import MonitorSDK from '@suite/shared/src/monitor-sdk';
import { SDKType } from '@suite/shared/src/monitor-sdk/types/';

function initMonitor(): void {
  const monitor = new MonitorSDK(SDKType.tam, {
    id: window.AppConfig.aegisMoniterId,
    env: process.env.VUE_APP_NODE_ENV,
    aid: false,
  });
  if (!monitor) return;
  monitor.loadSDK().then((agesInstance) => {
    window.aegisSDK = agesInstance as Aegis;
    // 从 url 上获取子应用名
    const appName = location.pathname.split('/')[1] as MicroApp.AppKeyType | undefined;
    perfAppStart(appName);
  });
}

// 配置了 RUM 监控上报 ID 才加载资源
if (process.env.VUE_APP_NODE_ENV === 'production' && window.AppConfig.aegisMoniterId) {
  initMonitor();
}
