/*
 * @Author: kelvinywang
 * @Date: 2022-04-21 11:42:38
 * @LastEditors: kelvinywang
 * @LastEditTime: 2022-09-05 15:06:29
 * @Description: 调试基座
 */
interface DebugAppOpts {
  name: MicroApp.AppKeyType,
  baseroute: string,
  url: string,
}

// localstorage 内存储的本地调试应用信息 KEY
const DEBUG_MICRO_APP_KEY = 'debugeMicroApp';

/**
 * 修改版本配置内的子应用配置
 */
const amendAppConfigMicroApp = function amendAppConfigMicroApp(): void {
  try {
    const debugMicroAppStr = localStorage.getItem(DEBUG_MICRO_APP_KEY) as string;
    if (!debugMicroAppStr) return;
    const { microApps } = window.FrontConfig;
    const debugMicroApps: DebugAppOpts[] =  JSON.parse(debugMicroAppStr);
    debugMicroApps.forEach((debugApp) => {
      const currentApp = microApps.find(app => app.id === debugApp.name);
      if (currentApp) {
        Object.assign(
          currentApp,
          {
            baseroute: debugApp.baseroute,
            url: debugApp.url,
          },
        );
      } else {
        console.error(`未找到 App 配置信息: ${debugMicroAppStr}`);
      }
    });
  } catch (error) {
    console.error('DEBUG AmendAppConfig Error:', error);
  }
};


/**
 * 设置微前端子应用配置
 * @param microAppOpts 微前端应用配置
 */
const setMicroApp = function setMicroApp(microAppOpts: DebugAppOpts | DebugAppOpts[]): void {
  const scopeAppOpts = Array.isArray(microAppOpts) ? microAppOpts : [microAppOpts];
  localStorage.setItem(DEBUG_MICRO_APP_KEY, JSON.stringify(scopeAppOpts));
  window.location.href = scopeAppOpts[0].baseroute as string;
};

/**
 * 打开全局弹出框
 * @param options 内置参数
 * @param payload 业务参数
 */
const openGlobalPopupSlot = function openGlobalPopupSlot(
  options: Record<string, any>,
  payload: Record<string, any>,
): void {
  const event = new CustomEvent('open-global-popup', { detail: { options, payload } });
  window?.dispatchEvent?.(event);
};

/**
 * 注册 APP 调试方法
 */
const registerDebugGlobalUtils = async function registerDebugGlobalUtils(): Promise<void> {
  // 正式环境不增加调试逻辑
  if (process.env.VUE_APP_NODE_ENV === 'production') return;
  // 必须等到配置返回后再执行
  await window.SuitePreFetch.getFrontendCommonConfig;
  amendAppConfigMicroApp();
  window.GlobalDebugUtils = {
    setMicroApp,
    openGlobalPopupSlot,
  };
};

registerDebugGlobalUtils();
